<template>
<v-container fluid>
  <v-row>
    <v-col>
      <base-material-card icon="mdi-account-group">
        <template v-slot:heading>
          <h3 class="display-2">Input Penyidik PNS</h3>
        </template>

        <crud
          :crudSettings="crudSettings"
          :headers="headersInputPPNS"
          @onTableChange="updateTableInputPPNS"
          :responseData="itemsDataPPNS"
          @onEditClick="editItem"
          @onDeleteClick="deleteItem"
        >

        <template v-slot:append-header>
          <v-col cols="9" style="padding-top:30px">
            <v-select
              class="float-left"
              item-text="b"
              item-value='k'
              :items="bulanList"
              label="Pilih Bulan"
              dense
              @change="filterBulan"
            ></v-select>
          </v-col>
          <v-col cols="4">
            <v-btn color="green" @click="createItem()" small dark class="mx-1"><v-icon small left>mdi-plus</v-icon> Tambah Penyidikan</v-btn>
          </v-col>
        </template>

        </crud>

      </base-material-card>
    </v-col>
  </v-row>

  <!-- The following below is the form for adding PPNS report -->
  <v-dialog v-model="dialogTambahDataPenyidikan" max-width="600px">
    <v-card>
      <v-card-text>
        <v-form ref="formTambah">
          <h1 class="display-2 my-2">Entri Lapor Penyidikan</h1>
          <!-- <v-spacer></v-spacer> -->

          <!-- <v-text-field v-model="formEdit.tanggal" :rules="[rules.required]" type="datetime-local" dense label="Tanggal" outlined ></v-text-field> -->
          <v-menu
            v-model="fromDateMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            min-width="290px"
            transition="scale-transition"
            lazy offset-y full-width>
            <template v-slot:activator="{ on }">
              <!-- :value="fromDateDisp" -->
              <v-text-field label="Tanggal" :rules="[rules.required]" append-icon="mdi-calendar" outlined dense readonly v-model="fromDateVal" v-on="on"></v-text-field>
            </template>
            <v-date-picker
            locale="in-ID"
            :min="getStartDate" :max="getEndDate"
            v-model="fromDateVal"
            no-title
            @input="fromDateMenu = false"
            >
            </v-date-picker>
          </v-menu>

          <v-text-field v-model="formEdit.spintgas" :rules="[rules.required]" dense label="SPINTGAS (Surat Perintah Tugas)" outlined ></v-text-field>

          <v-text-field v-model="formEdit.laporan" :rules="[rules.required]" dense label="Laporan Kejadian" outlined ></v-text-field>

          <v-text-field v-model="formEdit.sprindik" :rules="[rules.required]" dense label="SPRINDIK (Surat Perintah Penyidikan)" outlined ></v-text-field>

          <v-text-field v-model="formEdit.bap" :rules="[rules.required]" dense outlined label="BAP (Berita acara pemeriksaan) / BAI (Berita acara interogasi)"></v-text-field>

          <v-text-field v-model="formEdit.no_surat" :rules="[rules.required]" dense outlined label="No. PERDA"></v-text-field>

          <v-text-field v-model="formEdit.jenis" :rules="[rules.required]" dense outlined label="Tentang"></v-text-field>

          <v-text-field v-model="formEdit.pasal" :rules="[rules.required]" dense outlined label="Pasal"></v-text-field>

          <v-text-field v-model="formEdit.nama" :rules="[rules.required]" dense outlined label="Nama Pelanggar"></v-text-field>

          <file-input ref="fileInput" :errorInput="fileInputErrorFlag" :uploadUrl="uploadUrl" @delFileUploaded="delFormFile" @fileUploaded="addFormFile"></file-input>

        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="tutup()">Tutup</v-btn>
        <v-btn color="success" @click="simpanItem()">Simpan</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

</v-container>
</template>

<script>
import _ from 'lodash'
import store from '@/store'
import Crud from '@/components/common/Crud'
import FileInput from '@/components/common/FileInputPPNS'
import InputPPNSService from '@/services/InputPPNSService'
import {defaultRules} from '@/utils/lib'
import axios from 'axios'
import _g from '../../global'

export default {
  components:{
    Crud,
    FileInput
  },

  data(){
    return{
      fromDateMenu: false,
      fromDateVal: null,
      date: new Date(),
      uploadState: true,
      message: '',
      message_file: '',
      dateMenu: false,
      dateValue: null,
      dialogTambahDataPenyidikan:false,
      fileInputErrorFlag:false,
      crudSettings:{
        title:'',
        detailTitle:'Detail',
        deleteTitleKey:'no_surat',
        enableHeader:false,
        enableDetail:false,
        enableCreate:false,
        enableEdit:true,
        enableDelete:true,
      },
      headersInputPPNS:[
        {text:'No', value:'no', sortable:false, class:'header-index-profile'},
        {text:'Tanggal', value:'tanggal', sortable:false, class:'header-index-profile'},
        {text:'Tentang', value:'jenis', sortable:false, class:'header-index-profile'},
        {text:'No. Perda', value:'no_surat', sortable:false, class:'header-index-profile'},
        {text:'Barang Bukti', value:'foto', sortable:false, class:'header-index-profile'},
        {value:'aksi', sortable:false, class:'header-index-profile'},
      ],
      formTambah:{},
      formEdit:{file:[],},
      bulanList:[
        { b:'Januari',k:1},
        { b:'Febuari',k:2},
        { b:'Maret',k:3},
        { b:'April', k:4},
        { b:'Mei', k:5},
        { b:'Juni', k:6},
        { b:'Juli', k:7},
        { b:'Agustus', k:8},
        { b:'September', k:9},
        { b:'Oktober', k:10},
        { b:'November', k:11},
        { b:'Desember', k:12},
      ],
      itemsDataPPNS: {},
      rules:{
        ...defaultRules
      },
      files_: null,
      editState: false,
      allowed: null
    }
  },

  computed:{
    helpText(){
      return 'Unggah Barang Bukti Penyidikan'
    },
    uploadUrl(){
      return process.env.VUE_APP_API_URL + '/api/mb-upload/ppns/'
    },
    parseFile(){
      if(!this.itemsDataPPNS.foto){
        return '-'
      }else{
        return JSON.parse(this.itemsDataPPNS.foto)
      }
    },
    getStartDate() {
      let startDate
      if(this.formEdit.tanggal != undefined){
        let tanggal_ = new Date(this.formEdit.tanggal+'T00:00:00')
        startDate = new Date(tanggal_.getFullYear(), tanggal_.getMonth()+1, 1)
      }else{
        startDate = new Date(this.date.getFullYear(), this.date.getMonth(), 1)
      }
      return startDate.getFullYear()+'-'+this.addZero(startDate.getMonth())+'-'+this.addZero(startDate.getDate())
    },
    getEndDate() {
      let endDate
      if(this.formEdit.tanggal != undefined){
        let tanggal_ = new Date(this.formEdit.tanggal+'T00:00:00')
        let last_date = new Date(tanggal_.getFullYear(), tanggal_.getMonth() + 1, 0)
        endDate = new Date(tanggal_.getFullYear(), tanggal_.getMonth(), last_date.getDate())
      }else{
        endDate = new Date(this.date.getFullYear(), this.date.getMonth() + 1, 0)
      }
      return endDate.getFullYear()+'-'+this.addZero(endDate.getMonth()+1)+'-'+this.addZero(endDate.getDate())
    },
  },

  methods:{
    addFormFile(file){
      this.formEdit.file.push(file.name)
      console.log("added_file",this.formEdit.file)
    },
    delFormFile(file){
      let regex = /\d+_([\w\s-]+)-[\w\d]+\.(\w+)/
      console.log("file_", file.file.name)
      let filename = file.file.name
      this.formEdit.foto = this.formEdit.file.filter((val)=>{
        const match = val.match(regex)
        let curr_filename = match[1]+'.'+match[2]
        if (match){
          return filename != curr_filename
        }
        return false
      })
    },
    filterBulan(bulan_){
      console.log("bulan",bulan_)
      let bulan = bulan_
      this.updateTableInputPPNS(bulan)
    },
    updateTableInputPPNS(bulan_){
      if(bulan_ == undefined){
        bulan_ = (new Date()).getMonth()+1
      }
      console.log("bulan",bulan_)
      this.forceUpdate = false
      // this.tableValueTahunan = val ? val: this.tableValueTahunan
      // this.loadingTahunan = true
      this.itemsDataPPNS = {}

      var current = this.$store.getters["user/current"]
      var base_url_api = _g.getBaseURLAPIERK(current.year)
      const url_get_data = base_url_api + 'v1/get-data-ppns'
      axios.post(url_get_data, {
        nip: current.username,
        bulan: bulan_,
        api: 1
      }).then(response => {
        // console.log('tahunan',response.data)
        this.itemsDataPPNS = response.data
        console.log(this.itemsDataPPNS)
        this.allowed = response.data.allowed
        console.log(this.itemsDataPPNS)
      }).catch(err => {
        var error = err.message
        store.commit('snackbar/setSnack',{message: error, color:'error'})
      })

      // InputPPNSService.getDataPPNS({
      //   bulan:bulan_
      //   })
      // .then((response)=>{
      //   console.log('tahunan',response.data)
      //   this.itemsDataPPNS = response.data
      //   console.log(this.itemsDataPPNS)
      // }).finally(()=>{
      //   this.loadingTahunan = false
      // })
    },

    clearForm(){
      this.formEdit = {}
      this.fromDateVal = null
      this.selectedJenisPBJ = null
      this.formEdit.file=[]
      this.editState = false
    },

    createItem(){
      this.clearForm()
      this.dialogTambahDataPenyidikan=true
      // this.$refs.formTambah.resetValidation()
      if (this.$refs.fileInput){
        this.$refs.fileInput.clearAll()
      }
      this.formTambah.file=[]
      this.formEdit = {}
      this.formEdit.file=[]
      this.editState = false
      setTimeout(()=>{
        document.getElementsByClassName('v-dialog--active')[0].scrollTop = 0
      },500)
    },
    simpanItem(){
      // var base_url = window.location.origin
      // let text = '-';
      // const current_date = new Date()
      // const date_last_allowed = new Date()
      // In Javascript, date start from 0
      // date_last_allowed.setFullYear(2022, 11, 19)
      // if(this.allowed == false || this.allowed == null){
      //   if (current_date > date_last_allowed) {
      //     text = 'Mohon maaf, hari pelaporan sudah ditutup.'
      //     this.dialogTambahDataPenyidikan=false
      //     this.editState = false
      //     window.location.href = base_url + '/#/ppns'
      //     return store.commit('snackbar/setSnack',{message: text, color:'warning'})
      //   }

      //   var current_hour_minutes = new Date();
      //   current_hour_minutes.setHours(current_hour_minutes.getHours(), current_hour_minutes.getMinutes(), current_hour_minutes.getMilliseconds());
      //   var last_minutes_allowed = new Date(current_hour_minutes);
      //   last_minutes_allowed.setHours(23);
      //   last_minutes_allowed.setMinutes(59);
      //   if(current_hour_minutes >= last_minutes_allowed) {
      //     text = 'Mohon maaf, waktu pelaporan sudah ditutup.'
      //     this.dialogTambahDataPenyidikan=false
      //     this.editState = false
      //     window.location.href = base_url + '/#/ppns'
      //     return store.commit('snackbar/setSnack',{message: text, color:'warning'})
      //   }
      // }
      if (this.$refs.formTambah.validate()){
        this.formEdit.tanggal = this.fromDateVal
        // this.formEdit.is_pembalik = this.formEdit.is_pembalik === undefined ? false : this.formEdit.is_pembalik
        // this.formEdit.is_skp_iki = this.formEdit.is_skp_iki === undefined ? false : this.formEdit.is_skp_iki

        // if(this.formEdit.tanggal.length > 10){
        //   this.formEdit.tanggal = this.formEdit.tanggal.substring(0,10)
        // }
        // if(this.formEdit.file != undefined && this.formEdit.file != null && this.formEdit.file.length != null && this.formEdit.file.length != 0){
        if(this.formEdit.file != undefined && this.formEdit.file != null && this.formEdit.file.length != null){
          if(this.formEdit.file.length > 0){
            if(this.formEdit.file.length > 0 && this.formEdit.file.length < 5){
              let _join = ""
              for (let i = 0; i < this.formEdit.file.length; i++) {
                //loop the file to be string
                _join += this.formEdit.file[i].replace(/[\[\]']+/g,'')+"," /* eslint-disable-line */
              }
              this.formEdit.file = "[" + _join.replace(/,(?=\s*$)/, '') + "]" //remove last comma
            }
          }else{
            return store.commit('snackbar/setSnack',{message: "Maaf, Anda Belum Melampirkan File.", color:'error'})
          }
        }else{
          this.formEdit.tanggal = this.formEdit.tanggal.substring(0,10)
          return store.commit('snackbar/setSnack',{message: "Maaf, Anda Belum Melampirkan Evidence/Bukti Laporan.", color:'error'})
        }
        
        if(this.editState == true){
          InputPPNSService.updateDataPPNS(this.formEdit, this.formEdit.id).then(response => {
            let res = response.data
            if (res.status){
              store.commit('snackbar/setSnack',{message: 'Input Penyidik Berhasil Diupdate!', color:'success'})
              this.editState = false
              this.dialogTambahDataPenyidikan = false
              this.updateTableInputPPNS()
            } else {
              this.editState = false
              store.commit('snackbar/setSnack',{message: res.message, color:'error'})
            }
          })
        }else{
          InputPPNSService.storeDataPPNS(this.formEdit).then(response => {
            let res = response.data
            if (res.status){
              store.commit('snackbar/setSnack',{message: 'Input Penyidik Berhasil Dilaporkan!', color:'success'})
              this.dialogTambahDataPenyidikan = false
              this.updateTableInputPPNS()
            } else {
              store.commit('snackbar/setSnack',{message: res.message, color:'error'})
            }
          })
        }
      }
    },
    addZero(i) {
      if (i < 10) {
        i = "0" + i;
      }
      return i;
    },
    editItem(item){
      if (this.$refs.fileInput){
        this.$refs.fileInput.clearAll()
      }
      this.formEdit = item; this.dialogTambahDataPenyidikan = true; this.formEdit.file=[]
      // let tanggal = this.formEdit.tanggal.substring(8,10); let bulan = this.formEdit.tanggal.substring(5,7); let tahun = this.formEdit.tanggal.substring(0,4)
      let d = new Date(); let h = this.addZero(d.getHours()); let m = this.addZero(d.getMinutes()); //let s = this.addZero(d.getSeconds());
      // this.formEdit.tanggal = tanggal + "/" + bulan + "/" + tahun + " " + h + ":" + m
      // this.formEdit.tanggal = this.formEdit.tanggal + "T" + h + ":" + m
      // this.formEdit.tanggal = this.formEdit.tanggal + " " + h + ":" + m
      this.fromDateVal = this.formEdit.tanggal
      this.formEdit = _.clone(this.formEdit)
      this.editState = true
      setTimeout(()=>{
        document.getElementsByClassName('v-dialog--active')[0].scrollTop = 0
      },500)
    },
    deleteItem(item){
      this.formEdit = item
      InputPPNSService.hapusDataPPNS(this.formEdit).then((response)=>{
        // let res = response.data
        if (response.status){
          store.commit('snackbar/setSnack',{message: 'Data Penyidik Berhasil Dihapus!', color:'success'})
          // store.commit('snackbar/setSnack',{message: response.message, color:'success'})
          this.updateTableInputPPNS()
        }else {
          store.commit('snackbar/setSnack',{message: response.message, color:'error'})
        }
      })
    },
    tutup(){
      this.dialogTambahDataPenyidikan=false
      this.editState = false
      return this.updateTableInputPPNS()
    }
  },

  created(){
    this.allowed = null
    // let val = {itemsPerPage:10, page: 1}
    // this.updateTableInputPPNS(val);
    this.updateTableInputPPNS();
  }
}
</script>

<style lang="css" scoped>
.label{
  font-size:12px
}
</style>
