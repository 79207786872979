import request from '@/utils/request'
import store from '@/store'
import _g from '../../src/global'

const InputPPNSService = {
    getDataPPNS,
    storeDataPPNS,
    actionLogInsertDataPPNS,
    actionLogDeleteDataPPNS,
    uploadFileDataPPNS,
    updateDataPPNS,
    hapusDataPPNS
}

function getDataPPNS({
    // page,
    // perpage,
    // search,
    // order,
    // order_direction,
    bulan
  }) {
    var current = store.state.user.data
    let user = store.state.user
    if(user.current.role.includes("PLT") || user.current.role.includes("Kepala UPT Puskesmas") || user.current.role.includes("PLH")){
      current.username = _g.overrideWithPLT(user.current.role)
    }else{
      current.username = user.current.username
    }
    console.log('current', current)
    return request({
      url: '/ppns/list',
      method: 'post',
      data: {
        nip: current.username,
        bulan: bulan
        // page: page,
        // perpage: perpage ? perpage : 20,
        // search: search ? search : '',
        // order: order ? order : 'sasaran',
        // order_direction: order_direction ? order_direction : 'asc'
      }
    })
  }

function storeDataPPNS(data){
  var current = store.state.user.current
  let user = store.state.user
  if(user.current.role.includes("PLT") || user.current.role.includes("Kepala UPT Puskesmas") || user.current.role.includes("PLH")){
    current.username = _g.overrideWithPLT(user.current.role)
  }else{
    current.username = user.current.username
  }
  return request({
    url: '/ppns/store',
    method: 'post',
    data: {
      nip: current.username,
      // "file": data.file,
      "foto": data.file,
      "jenis": data.jenis,
      "tanggal": data.tanggal,
      "no_surat": data.no_surat,
      "spintgas": data.spintgas,
      "laporan": data.laporan,
      "sprindik": data.sprindik,
      "bap": data.bap,
      "pasal": data.pasal,
      "nama": data.nama,
    }
  })
}

function updateDataPPNS(data, id){
  var current = store.state.user.current
  let user = store.state.user
  if(user.current.role.includes("PLT") || user.current.role.includes("Kepala UPT Puskesmas") || user.current.role.includes("PLH")){
    current.username = _g.overrideWithPLT(user.current.role)
  }else{
    current.username = user.current.username
  }
  return request({
    url: '/ppns/update',
    method: 'post',
    data: {
      id: id ? id : null,
      nip: current.username,
      "file": data.file,
      "foto": data.file,
      "jenis": data.jenis,
      "tanggal": data.tanggal,
      "no_surat": data.no_surat,
      "spintgas": data.spintgas,
      "laporan": data.laporan,
      "sprindik": data.sprindik,
      "bap": data.bap,
      "pasal": data.pasal,
      "nama": data.nama,
    }
  })
}

function hapusDataPPNS(data){
  var current = store.state.user.current
  let user = store.state.user
  if(user.current.role.includes("PLT") || user.current.role.includes("Kepala UPT Puskesmas") || user.current.role.includes("PLH")){
    current.username = _g.overrideWithPLT(user.current.role)
  }else{
    current.username = user.current.username
  }
  return request({
    url: '/ppns/delete',
    method: 'post',
    data: {
      id: data.id ? data.id : null,
      nip: current.username,
    }
  })
}

function actionLogInsertDataPPNS(){

}

function actionLogDeleteDataPPNS(){

}

function uploadFileDataPPNS(){

}

export default InputPPNSService